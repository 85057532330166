var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DZLyl {\n  background: url(" + escape(require("../../../assets/images/yancey-official-blog-cat-scroll.png")) + ") no-repeat 0 0;\n  width: 70px;\n  height: 900px;\n  position: fixed;\n  top: -60rem;\n  right: 25px;\n  transition: 600ms all cubic-bezier(0.25, 0.1, 0.3, 1.5);\n  animation: _2zqxY 2s linear infinite;\n  outline: none; }\n  .DZLyl:hover {\n    cursor: pointer; }\n\n@keyframes _2zqxY {\n  0% {\n    transform: translateY(0); }\n  50% {\n    transform: translateY(-6px); }\n  100% {\n    transform: translateY(0); } }\n", ""]);

// exports
exports.locals = {
	"back_to_top": "DZLyl",
	"float": "_2zqxY"
};