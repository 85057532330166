exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".gZg-X {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background: rgba(255, 255, 255, 0.99); }\n  .gZg-X .t40QH {\n    position: absolute;\n    top: 1.2rem;\n    right: 1.6rem;\n    width: 1.6rem;\n    height: 1.6rem;\n    fill: #B5B5B5;\n    cursor: pointer; }\n  ._3o7Wq {\n    position: relative;\n    width: 30rem;\n    margin: 0 auto;\n    top: 36vh; }\n    ._3o7Wq ._3uM7v {\n      margin-bottom: 1.2rem;\n      margin-left: 1.2rem;\n      color: #404040;\n      font-size: .9rem; }\n    ._3o7Wq .DHnxD {\n      position: absolute;\n      top: 3rem;\n      left: .9rem;\n      fill: #B5B5B5;\n      width: 1.6rem;\n      height: 1.6rem; }\n    ._3o7Wq input {\n      width: 30rem;\n      padding: .6rem 1.2rem .6rem 3.2rem;\n      color: #b5b5b5;\n      font-family: \"Ubuntu\", sans-serif;\n      font-size: 1.4rem;\n      outline: 0;\n      border: 1px solid #8f8f8f;\n      border-radius: 2.7rem;\n      transition: all 100ms linear; }\n      ._3o7Wq input:focus {\n        color: #111;\n        transition: all 100ms linear; }\n  .gZg-X ._1E40L {\n    position: absolute;\n    right: 2rem;\n    bottom: 2rem;\n    background: no-repeat center center;\n    background-size: cover;\n    width: 16rem;\n    height: 35rem; }\n", ""]);

// exports
exports.locals = {
	"search_full_screen": "gZg-X",
	"icon_close": "t40QH",
	"search_cell": "_3o7Wq",
	"search_title": "_3uM7v",
	"search_icon": "DHnxD",
	"miku_chan": "_1E40L"
};