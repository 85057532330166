exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Gwz5v {\n  font-family: 'Ubuntu', sans-serif;\n  padding: 1.8rem 0;\n  letter-spacing: 0.025rem;\n  background: #fff; }\n  .Gwz5v ._3c3zX {\n    margin: 0 auto;\n    width: 40rem; }\n  ._2VYCV {\n    margin-bottom: 0.3rem;\n    font-size: 0.75rem;\n    font-weight: bold;\n    color: #2c2e2f; }\n    ._2VYCV .FjWK9 {\n      width: 0.9rem;\n      height: 0.9rem;\n      position: relative;\n      top: 0.2rem;\n      margin: 0 0.2rem;\n      animation: _2amIk 1500ms infinite linear; }\n  .Gwz5v .lbr7N {\n    width: 100%;\n    height: 1px;\n    background: linear-gradient(to right, #b7bcbf 50%, rgba(255, 255, 255, 0) 40%) repeat-x top;\n    background-size: 3px 1px;\n    margin: 0;\n    padding: 0;\n    border-top: 0;\n    border-bottom: 0;\n    box-sizing: content-box; }\n  .esbL1 {\n    display: flex;\n    justify-content: space-between;\n    padding-top: 1rem;\n    font-size: 0.65rem;\n    color: #6c7378; }\n    .esbL1 ._5990z {\n      text-shadow: 0 0 1px rgba(0, 0, 0, 0.1); }\n    ._3vXrS {\n      display: inline-block;\n      margin-left: 0.4rem;\n      padding-right: 0.4rem;\n      border-right: 1px solid #d9d9d9; }\n      ._3vXrS:last-child {\n        border-right: none;\n        padding-right: 0; }\n      ._3vXrS a {\n        color: #6c7378;\n        transition: all 300ms linear; }\n        ._3vXrS a:hover {\n          color: #000; }\n\n@keyframes _2amIk {\n  0% {\n    transform: scale(1.1); }\n  50% {\n    transform: scale(0.8); }\n  100% {\n    transform: scale(1.1); } }\n", ""]);

// exports
exports.locals = {
	"yancey_common_footer": "Gwz5v",
	"footer_container": "_3c3zX",
	"creator": "_2VYCV",
	"icon_heart": "FjWK9",
	"pluse": "_2amIk",
	"dot_split": "lbr7N",
	"copyright_wrapper": "esbL1",
	"copyright": "_5990z",
	"copyright_item": "_3vXrS"
};